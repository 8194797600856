import React, { useState } from 'react';

import { withFormik } from 'formik';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { Button, Form, Grid } from 'semantic-ui-react';
import { has, isEmpty } from 'lodash';

import Actions from 'src/state/root-actions';
import {
    FORM_MESSAGES,
    STRONG_PASSWORD_REGEX,
    getErrorMessage,
    getInfoMessage,
} from 'src/utils/form-utils';

const ResetPasswordForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    email,
    code,
}) => {
    const [showOldPassword, setShowOldPassword] = useState(false);
    const toggleShowOldPassword = () => setShowOldPassword(!showOldPassword);

    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);

    const isChangePassword = isEmpty(code);
    const isResetPassword = !isChangePassword;

    /**
     * Initialized conditional form values
     */
    if (isChangePassword) {
        // oldPassword is only a field for Settings -> Change Password
        values.oldPassword = '';
    }

    if (isResetPassword) {
        // code is only a field when coming from Forgot Password -> Email Link -> ResetPassword
        values.code = code;
    }

    return (
        <Form onSubmit={handleSubmit} size="large">
            {isResetPassword && (
                <input
                    type="hidden"
                    id="reset-password-code"
                    name="resetPasswordCode"
                    value={code}
                    aria-label="Cognito Code (hidden form input)"
                />
            )}
            <input
                type="hidden"
                id="reset-password-email"
                name="resetPasswordEmail"
                value={email}
                aria-label="Email (hidden form input)"
            />
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        {errors?.general && (
                            <h2 style={{ color: 'red' }}>{errors.general}</h2>
                        )}
                        {isChangePassword && (
                            <Form.Field>
                                <Form.Input
                                    action={
                                        <Button
                                            type="button"
                                            icon={`eye${
                                                showOldPassword
                                                    ? ' slash outline'
                                                    : ''
                                            }`}
                                            onClick={toggleShowOldPassword}
                                        />
                                    }
                                    label={{
                                        children: 'Old Password',
                                        htmlFor: 'old-password',
                                        className: values.oldPassword
                                            ? ''
                                            : 'hidden',
                                    }}
                                    id="old-password"
                                    name="oldPassword"
                                    type={showOldPassword ? 'text' : 'password'}
                                    placeholder="Old Password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.oldPassword}
                                    error={
                                        errors.oldPassword &&
                                        touched.oldPassword
                                            ? getErrorMessage(
                                                errors.oldPassword
                                            )
                                            : false
                                    }
                                />
                            </Form.Field>
                        )}
                        <Form.Field>
                            <Form.Input
                                action={
                                    <Button
                                        type="button"
                                        icon={`eye${
                                            showPassword ? ' slash outline' : ''
                                        }`}
                                        onClick={toggleShowPassword}
                                    />
                                }
                                label={{
                                    children: 'New Password',
                                    htmlFor: 'new-password',
                                    className: values.password ? '' : 'hidden',
                                }}
                                id="new-password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="New Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={
                                    errors.password && touched.password
                                        ? getErrorMessage(errors.password)
                                        : false
                                }
                            />
                            {!(errors.password && touched.password) &&
                                getInfoMessage(
                                    FORM_MESSAGES.passwordValidation,
                                    false
                                )}
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            type="submit"
                            disabled={isSubmitting || !isEmpty(errors)}
                            className="margin-top--small margin-bottom--medium"
                            primary
                            fluid
                        >
                            SAVE
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

const handleSubmit = (formValues, { props, setFieldError, setSubmitting }) => {
    const onFulfill = () => setSubmitting(false);
    const onFailure = (message) => setFieldError('general', message);
    const onSuccess = () => {
        props.dispatch(
            Actions.auth.login.trigger({
                ...formValues,
                onSuccess: () => {
                    navigate(props.redirectPath, {
                        state: { isResetPasswordSuccess: true },
                    });
                },
                onFailure: () => {
                    navigate('/continue', {
                        state: { isResetPasswordSuccess: true },
                    });
                },
            })
        );
    };

    props.dispatch(
        Actions.auth.confirmForgotPassword.trigger({
            ...formValues,
            onFailure,
            onSuccess,
            onFulfill,
        })
    );
};

const validate = (values) => {
    const error = {};

    if (!values.password) {
        error.password = FORM_MESSAGES.requiredField;
    } else if (!STRONG_PASSWORD_REGEX.test(values.password)) {
        error.password = FORM_MESSAGES.passwordValidation;
    }

    if (has(values, 'oldPassword') && !values.oldPassword) {
        error.oldPassword = FORM_MESSAGES.requiredField;
    }

    return error;
};

const FormikResetPasswordForm = withFormik({
    handleSubmit,
    validate,
    mapPropsToValues: (props) => ({
        email: props.email || '',
        password: '',
    }),
})(ResetPasswordForm);

export default connect()(FormikResetPasswordForm);

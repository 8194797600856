import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { navigate } from 'gatsby';
import { Container, Grid, Image } from 'semantic-ui-react';

import ResetPasswordForm from 'src/components/auth/ResetPasswordForm';
import SEO from 'src/components/SEO';
import withLocation from 'src/components/withLocation';
import AuthPageWrap from 'src/components/auth/AuthPageWrap';

const ResetPasswordPage = ({
    search: { email, confirmation_code: code, redirectPath },
}) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        // if no email, redirect to /continue
        if (isEmpty(email)) {
            navigate('/continue');
        }

        // update location to remove query string from url
        if (window?.history) {
            window.history.replaceState(
                {},
                '',
                window.location.href.split('?')[0]
            );
        }
    }, []);

    return (
        <AuthPageWrap>
            <SEO title="P.Volve Reset Password" />
            <Image
                src="https://cdn.shopify.com/s/files/1/2379/5349/files/pvolve-logo.jpg"
                size="small"
                centered
            />
            <Container textAlign="center">
                <h2 className="margin-top--small">Create your new password.</h2>
            </Container>
            <Grid centered columns={3}>
                <Grid.Row>
                    <Grid.Column>
                        <p className="secondaryText margin-top--small">
                            Create a new password for your account associated
                            with {email}
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div className="margin-top--large">
                <ResetPasswordForm
                    code={code}
                    email={email}
                    redirectPath={redirectPath}
                />
            </div>
        </AuthPageWrap>
    );
};

export default withLocation(ResetPasswordPage);
